<!--
 * @Description: 用户端 首页-支付信息 payInfo
 * @Author: zhoucheng
 * @Github: 无
 * @Date: 2021-04-01 01:02:46
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="支付信息"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="receiptBox">
      <van-row class="receipt">
        <van-image class="ticketPic"
                   :src="require('../../../assets/homePage/ticket.png')" />
        <span class="ticketWords"
              @click="handleClickInvoice">开发票</span>
      </van-row>
    </van-row>
    <van-row class="contain">
      <van-row class="detailsBox"
               v-for="item in paymentRecordList"
               :key="item.carId"
               @click="toDetails(item)">
        <van-col :span="17"
                 class="details">
          <van-row class="lineTwo">
            <van-image class="datePic"
                       :src="require('../../../assets/homePage/date.png')" />
            <span class="word">{{item.paymentTime}}</span>
          </van-row>
          <van-row class="lineThree">
            <van-image class="pricePic"
                       :src="require('../../../assets/homePage/pay.png')" />
            <span class="word">{{item.paymentMoneyAmount}}元</span>
          </van-row>
        </van-col>
        <van-col :span="7"
                 class="payMethodsBox">
          <span class="payMethods">{{item.payMethod}}</span>
          <van-row class="arrowBox">
            <van-image class="arrow"
                       :src="require('../../../assets/homePage/arrow.png')" />
          </van-row>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      list: [],
      paymentRecordList: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getPayInfo()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取支付信息
    getPayInfo () {
      this.$payInfo.getPaymentRecord().then(res => {
        this.paymentRecordList = res.resultEntity
        this.paymentRecordList.forEach(item => {
          item.paymentMoneyAmount = (item.paymentMoneyAmount / 100).toFixed(2)
        })
      })
    },
    // 支付详情
    toDetails (item) {
      this.$router.push(
        {
          name: 'payInfoInfo',
          query: {
            paymentSequence: item.paymentSequence,
            paymentTime: item.paymentTime
          }
        }
      )
    },
    // 开发票跳转
    handleClickInvoice () {
      this.$router.push('/invoiceCenter')
    },
    // 返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .receiptBox {
    margin-top: 1px;
    height: 40px;
    background: #fff;
    .receipt {
      position: relative;
      float: right;
      margin-top: 5px;
      right: 12px;
      height: 30px;
      width: 85px;
      background: #f2f7fa;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .ticketPic {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -6.5px;
        width: 14px;
        height: 13px;
        // margin: 5px 7px 0 10px;
      }
      .ticketWords {
        margin-left: 19px;
        display: inline-block;
        line-height: 30px;
        font-size: 15px;
        color: #333333;
      }
    }
  }
  .contain {
    padding: 12px;
    .detailsBox {
      background: #fff;
      border-radius: 5px;
      margin-bottom: 12px;
      .details {
        height: 62px;
        padding: 12px;
        .lineOne {
          height: 22px;
          line-height: 22px;
          .datePic {
            width: 13px;
            height: 11px;
            margin-right: 13px;
          }
        }
        .lineTwo {
          height: 22px;
          line-height: 22px;
          .datePic {
            width: 13px;
            height: 11px;
            margin-right: 13px;
          }
        }
        .lineThree {
          height: 20px;
          line-height: 20px;
          .pricePic {
            width: 12px;
            height: 13px;
            margin-right: 13px;
          }
        }
        .word {
          font-size: 15px;
          color: #333333;
        }
      }
      .payMethodsBox {
        padding-top: 12px;
        .payMethods {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          text-align: right;
          color: #999;
          font-size: 13px;
          width: 65px;
        }
        .arrowBox {
          display: inline-block;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          .arrow {
            width: 7px;
            height: 11px;
            margin-left: 11px;
          }
        }
      }
    }
  }
}
</style>
